import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useContractsList() {
  //User toast
  const toast = useToast();

  const refContractListTable = ref(null);

  const tableColumns = [
    { key: "contract_code", label: "合約編號", sortable: true },
    { key: 'client_code', label: '客戶編號' },
    { key: 'client.company_name_cn', label: '客戶名稱' },
    { key: "contract_name", label: "合約名稱" },
    { key: "contract_date", label: "合約日期" },
    { key: "contract", label: "合約", sortable: true },
    { key: "is_signed", label: "簽約狀態", sortable: true },
    { key: "signed_at", label: "簽署日期" },
    { key: "is_completed", label: "合約狀態", sortable: true },
    { key: "remark", label: "備註" },
    { key: "created_at", label: "生成日期", sortable: true },
    { key: "sale.name", label: "銷售顧問" },
    { key: "actions", label: "操作" },
  ];

  //Filter
  const userFilter = ref(null);
  const clientFilter = ref(null);
  const stageFilter = ref(null);
  const serviceFilter = ref(null);

  const perPage = ref(10);
  const totalContracts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refContractListTable.value
      ? refContractListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContracts.value,
    };
  });

  const refetchData = () => {
    refContractListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      userFilter,
      clientFilter,
      stageFilter,
      serviceFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchContracts = (ctx, callback) => {
    store
      .dispatch("contract/fetchContracts", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        create_id: userFilter.value,
        client_id: clientFilter.value,
        contract_stage_id: stageFilter.value,
        service_code_id: serviceFilter.value,
      })
      .then((response) => {
        const { contracts, total } = response.data;
        callback(contracts);
        totalContracts.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching contract list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchContracts,
    tableColumns,
    perPage,
    currentPage,
    totalContracts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refContractListTable,
    ability,
    refetchData,
    userFilter,
    clientFilter,
    stageFilter,
    serviceFilter,
  };
}
